<template>
  <div class="container directory" :data-id="tab">
    <ul :style="'--offsetTop: ' + offsetTop + 'px'">
      <li
        v-for="(item, index) in sections"
        :key="index"
        :ref="setItemRef"
        :data-id="index"
        :class="{ active: currentSection === index && isCurrentTab }"
      >
        <component
          class="name"
          :class="{ marked: isProjectEntered }"
          :is="
            currentSection === index && !isProjectEntered ? 'h1' : 'BackButton'
          "
          :directory-link="index"
        >
          {{ capitalize(index) }}
        </component>
        <component
          :is="tab === 'about' && index === 'bio' ? 'Bio' : 'Folder'"
          :directory="tab"
          :folder="index"
          :is-active="currentSection === index && isCurrentTab"
        />
      </li>
    </ul>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapState, mapGetters } from "vuex";
import { debounce } from "lodash";
/*import DirectoryLink from "@/components/DirectoryLink";*/
import Folder from "@/components/Folder";
import Bio from "@/components/Bio";
import BackButton from "@/components/BackButton";

export default {
  components: {
    /*DirectoryLink,*/
    Folder,
    Bio,
    BackButton,
  },
  props: ["tab", "isCurrentTab"],
  data() {
    return {
      folders: [],
      offsetTop: 0,
    };
  },
  computed: {
    ...mapState(["section"]),
    ...mapGetters([
      "getDirectoryById",
      "isProjectOpen",
      "isProjectEntered",
      "isDesktop",
    ]),
    currentSection() {
      return this.isCurrentTab ? this.section : null;
    },
    sections() {
      return this.getDirectoryById(this.tab);
    },
  },
  watch: {
    currentSection: {
      immediate: true,
      handler(val, oldVal) {
        this.$nextTick(() => {
          if (val) {
            let duration = typeof oldVal === "undefined" ? 0 : 450;
            let target = this.getTarget(val);
            this.offsetTop = 0 - target.top + 48;
            this.$emit("update:directory", true);
          } else if (oldVal) {
            this.offsetTop = 0;
            this.$emit("update:directory", false);
          }
        });
      },
    },
    isDesktop(val) {
      if (val && this.currentSection) {
        this.$nextTick(() => {
          this.offsetTop = 0;
          let target = this.getTarget(this.currentSection);
          this.offsetTop = 0 - target.top + 48;
          //this.offsetTop = 0 - target.offsetTop;
        });
      }
    },
  },
  methods: {
    capitalize(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    getTarget(id) {
      return this.folders
        .find((el) => el.attributes["data-id"].value === id)
        .getBoundingClientRect();
    },
    setItemRef(el) {
      if (el) {
        this.folders.push(el);
      }
    },
  },
  beforeUpdate() {
    this.folders = [];
  },
};
</script>

<style>
.section .container.directory {
  overflow: hidden;
}

.directory > ul {
  list-style-type: none;
}

.directory > ul li:not(.active) {
  /*display: none;*/
}

.directory > ul li .name {
  position: relative;
  display: block;
  height: 4.8rem;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 4.8rem;
  text-decoration: none;
  padding-left: 0.75em;
  border-bottom: 1px solid var(--primary-color);
  transition: background-color 0.2s ease-in-out;
}

.directory > ul li .name:before {
  content: "";
}

.directory > ul li .name:after {
  content: "‏‏‎";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Crect x='0.5' y='0.5' width='3' height='3' fill='transparent' stroke='black' stroke-width='1'/%3E%3Crect x='0.5' y='6.5' width='3' height='3' fill='transparent' stroke='black' stroke-width='1'/%3E%3Crect x='0.5' y='12.5' width='3' height='3' fill='transparent' stroke='black' stroke-width='1'/%3E%3Crect x='6.5' y='0.5' width='3' height='3' fill='transparent' stroke='black' stroke-width='1'/%3E%3Crect x='6.5' y='6.5' width='3' height='3' fill='transparent' stroke='black' stroke-width='1'/%3E%3Crect x='6.5' y='12.5' width='3' height='3' fill='transparent' stroke='black' stroke-width='1'/%3E%3Crect x='12.5' y='0.5' width='3' height='3' fill='transparent' stroke='black' stroke-width='1'/%3E%3Crect x='12.5' y='6.5' width='3' height='3' fill='transparent' stroke='black' stroke-width='1'/%3E%3Crect x='12.5' y='12.5' width='3' height='3' fill='transparent' stroke='black' stroke-width='1'/%3E%3C/svg%3E");
  width: 1.6rem;
  height: 1.6rem;
  margin: 1.6rem 1.3rem;
  float: right;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}

.directory > ul li .name.marked:after {
  opacity: 1;
}

.directory > ul li a.name:hover {
  background-color: var(--secondary-color);
}

.directory .content {
  width: 100%;
  height: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.directory .content.transition {
  transition: height 0.45s ease-in-out;
}

.directory .content.expanded {
  height: auto;
}

.directory .content .wrapper {
  min-height: calc(100 * var(--vh, 1vh) - 19.2rem);
}

@media only screen and (max-width: 768px) {
  .section .expanded .directory > ul li:not(.active) {
    display: none;
  }
}

@media only screen and (min-width: 769px) {
  .directory > ul {
    transition: all 0.45s ease-in-out;
    transform: translateY(var(--offsetTop));
  }

  .directory > ul li .name {
    height: initial;
  }

  .directory .content {
    width: calc(100% + 2rem);
    overflow: auto;
  }

  .directory .content.expanded {
    height: calc(100 * var(--vh, 1vh) - 9.6rem);
  }

  .directory .content .wrapper {
    width: calc(((100vw - 10rem) / 6) * 3);
    min-height: calc(100 * var(--vh, 1vh) - 9.6rem);
  }
}
</style>
